import { Button, IconButton } from '@chakra-ui/button';
import { useColorMode } from '@chakra-ui/color-mode';
import { Image } from '@chakra-ui/image';
import { Box, Flex, Heading, HStack, Spacer } from '@chakra-ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { HamburgerIcon } from '@chakra-ui/icons';

function NavBar() {
  const { colorMode } = useColorMode();

  let history = useHistory();

  const homeClick = () => {
    history.push('/');
  };

  const garbageClick = () => {
    history.push('/garbage');
  };

  const weatherClick = () => {
    history.push('/weather');
  };

  const contactClick = () => {
    history.push('/contacts');
  };

  const choresClick = () => {
    history.push('/chores');
  };

  return (
    <Flex
      p={2}
      bg={colorMode === 'light' ? 'white' : 'black'}
      alignItems="center"
    >
      <Image
        filter={colorMode === 'light' ? 'none' : 'invert(1)'}
        ml={10}
        boxSize="75px"
        color="inverse"
        src="https://dcassetcdn.com/design_img/2306814/617327/617327_12260572_2306814_071bd951_thumbnail.png"
      />
      <Box p={2}>
        <Heading size="md">
          <Link to="/">Consolation Island App </Link>
        </Heading>
      </Box>
      <Spacer />

      <Box p={2}>
        <HStack>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="outline"
            />
            <MenuList p={2}>
              <MenuItem onClick={homeClick}>
                <Button variant="link" colorScheme="facebook" mr={5}>
                  Home
                </Button>
              </MenuItem>

              <MenuItem onClick={garbageClick}>
                <Button variant="link" colorScheme="facebook" mr={5}>
                  Garbage
                </Button>
              </MenuItem>

              <MenuItem onClick={weatherClick}>
                <Button variant="link" colorScheme="facebook" mr={5}>
                  Weather
                </Button>
              </MenuItem>

              <MenuItem onClick={contactClick}>
                <Button variant="link" colorScheme="facebook" mr={5}>
                  Contacts
                </Button>
              </MenuItem>

              <MenuItem onClick={choresClick}>
                <Button variant="link" colorScheme="facebook" mr={5}>
                  Chores
                </Button>
              </MenuItem>
            </MenuList>
          </Menu>
          <ColorModeSwitcher justifySelf="flex-end" />
        </HStack>
      </Box>
    </Flex>
  );
}

export default NavBar;
