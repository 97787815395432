import { useColorMode } from '@chakra-ui/color-mode';
import { Box, Flex, Spacer, Text, VStack } from '@chakra-ui/layout';
import React, { useState } from 'react';
import { RiCloseCircleLine } from 'react-icons/ri';
import { TiEdit } from 'react-icons/ti';
import TodoForm from './TodoForm';

function Todo({ todos, completeTodo, removeTodo, updateTodo }) {
  const [edit, setEdit] = useState({
    id: null,
    value: '',
  });

  const { colorMode } = useColorMode();

  const submitUpdate = value => {
    updateTodo(edit.id, value);
    setEdit({
      id: null,
      value: '',
    });
  };

  if (edit.id) {
    return <TodoForm edit={edit} onSubmit={submitUpdate} />;
  }

  return todos.map((todo, index) => (
    <VStack mt={3} className={todo.isComplete && 'complete'}>
      <Flex
        className="todo-row"
        key={index}
        boxShadow={'lg'}
        background={colorMode === 'dark' ? 'blue.900' : 'blue.100'}
      >
        <Box>
          <Text
            key={todo.id}
            onClick={() => completeTodo(todo.id)}
            cursor="pointer"
          >
            {todo.text}
          </Text>
        </Box>
        <Spacer />
        <Box>
          <RiCloseCircleLine
            onClick={() => removeTodo(todo.id)}
            className="delete-icon"
          />
        </Box>
        <Box>
          <TiEdit
            onClick={() => setEdit({ id: todo.id, value: todo.text })}
            className="edit-icon"
          />
        </Box>
      </Flex>
    </VStack>
  ));
}

export default Todo;
