import { Button } from '@chakra-ui/button';
import { useColorMode } from '@chakra-ui/color-mode';
import { Input, InputGroup, InputRightAddon } from '@chakra-ui/input';
import { VStack } from '@chakra-ui/layout';
import React, { useState, useEffect, useRef } from 'react';

function TodoForm(props) {
  const [input, setInput] = useState(props.edit ? props.edit.value : '');

  const inputRef = useRef(null);
  const { colorMode } = useColorMode();

  useEffect(() => {
    inputRef.current.focus();
  });

  const handleChange = e => {
    setInput(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();

    props.onSubmit({
      id: Math.floor(Math.random() * 10000),
      text: input,
    });

    setInput('');
  };

  return (
    <VStack>
      <form onSubmit={handleSubmit} autoComplete="off">
        {props.edit ? (
          <InputGroup size="lg" mt={15}>
            <Input
              type="text"
              p="1.5rem"
              placeholder="Update..."
              value={input}
              name="text"
              onChange={handleChange}
              ref={inputRef}
            ></Input>
            <InputRightAddon
              background={colorMode === 'light' ? 'lightgreen' : 'darkblue'}
            >
              <Button type="submit" background="none">
                Update Chore
              </Button>
            </InputRightAddon>
          </InputGroup>
        ) : (
          <InputGroup size="lg" mt={15}>
            <Input
              type="text"
              p="1.5rem"
              placeholder="Add a Chore..."
              value={input}
              name="text"
              onChange={handleChange}
              ref={inputRef}
            ></Input>
            <InputRightAddon
              background={colorMode === 'light' ? 'lightblue' : 'green'}
            >
              <Button type="submit" background="none">
                Add Chore
              </Button>
            </InputRightAddon>
          </InputGroup>
        )}
      </form>
    </VStack>
  );
}

export default TodoForm;
