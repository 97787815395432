import React from 'react';
import {
  Heading,
  Divider,
  VStack,
  Container,
  Button,
  Box,
  Link,
  useColorMode,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react';
import { useHistory } from 'react-router';

function Contacts() {
  const { colorMode } = useColorMode();
  let history = useHistory();

  const homeBack = () => {
    history.push('/');
  };
  return (
    <Container mb={150}>
      <Button onClick={homeBack} variant="link" colorScheme="facebook">
        <Link to="/"> Home </Link>
      </Button>
      <VStack spacing={5}>
        <Box>
          <img
            src={
              colorMode === 'light'
                ? process.env.PUBLIC_URL + '/contactColour.png'
                : process.env.PUBLIC_URL + '/contactColour.png'
            }
            alt="garbage"
            width="100"
          />
        </Box>
        <Heading>Contacts</Heading>

        <Divider
          margin={10}
          width={250}
          opacity={0.8}
          borderBottomWidth={'3px'}
        />

        <Container
          minWidth="200px"
          minHeight="250px"
          boxShadow={'dark-lg'}
          mr={5}
          p={5}
          bg={colorMode === 'light' ? 'white' : 'black'}
          border="lightblue solid"
          borderRadius="15"
        >
          <Heading size={'sm'} textAlign="center">
            Important Phone Numbers
          </Heading>

          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Contact</Th>
                <Th isNumeric>Phone #</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>SSCA Emergency Response</Td>
                <Td isNumeric>
                  <a href="tel:+844-868-0068">844-868-0068</a>
                </Td>
              </Tr>
              <Tr>
                <Td>Coast Guard – Parry Sound</Td>
                <Td isNumeric>
                  <a href="tel:+1-705-746-2196">1-705-746-2196</a>
                </Td>
              </Tr>
              <Tr>
                <Td>OPP</Td>
                <Td isNumeric>
                  <a href="tel:+1-705-746-4225">1-705-746-4225</a>
                </Td>
              </Tr>
              <Tr>
                <Td>Hydro One Power Outage</Td>
                <Td isNumeric>
                  <a href="tel:+1-800-434-1235">1-800-434-1235</a>
                </Td>
              </Tr>
              <Tr>
                <Td>Telehealth Ontario</Td>
                <Td isNumeric>
                  <a href="tel:+1-866-797-0000">1-866-797-0000</a>
                </Td>
              </Tr>
              <Tr>
                <Td>Poison Information Centre</Td>
                <Td isNumeric>
                  <a href="tel:+1-800-268-9017">1-800-268-9017</a>
                </Td>
              </Tr>
              <Tr>
                <Td>Coast Guard – Maritime</Td>
                <Td isNumeric>
                  <a href="tel:+1-800-267-7270">1-800-267-7270</a>
                </Td>
              </Tr>
              <Tr>
                <Td>LeBlanc’s Sans Souci Marina</Td>
                <Td isNumeric>
                  <a href="tel:+1-705-746-5598">1-705-746-5598</a>
                </Td>
              </Tr>
              <Tr>
                <Td>Glenn Burney Marina</Td>
                <Td isNumeric>
                  <a href="tel:+1-705-746-6215">1-705-746-6215</a>
                </Td>
              </Tr>
              <Tr>
                <Td>Henry’s Fish Restaurant</Td>
                <Td isNumeric>
                  <a href="tel:+1-705-746-9040">1-705-746-9040</a>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Container>
      </VStack>
    </Container>
  );
}

export default Contacts;
