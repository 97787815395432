import { useColorMode } from '@chakra-ui/color-mode';
import { Container, Heading, HStack, Flex, Box } from '@chakra-ui/layout';
import React from 'react';
import { useHistory } from 'react-router';
import TodaysDate from '../TodaysDate/TodaysDate';
import './Home.css';

function Home() {
  const { colorMode } = useColorMode();

  let history = useHistory();

  const garbageClick = () => {
    history.push('/garbage');
  };

  const weatherClick = () => {
    history.push('/weather');
  };

  const contactClick = () => {
    history.push('/contacts');
  };

  return (
    <>
      <Container textAlign={'center'}>
        <TodaysDate />
      </Container>
      <HStack
        overflow={'auto'}
        p={5}
        spacing={5}
        maxWidth={1000}
        margin={'auto'}
      >
        <Container
          className="garbageHover"
          opacity="1"
          minWidth="200px"
          minHeight="250px"
          boxShadow={'dark-lg'}
          p={5}
          bg={colorMode === 'light' ? 'white' : 'black'}
          border="lightblue solid"
          borderRadius="15"
          onClick={garbageClick}
          cursor="pointer"
        >
          <Heading textAlign={'center'} pb={5}>
            Garbage
          </Heading>
          <Flex justifyContent={'center'}>
            <Box>
              <img
                src={
                  colorMode === 'light'
                    ? process.env.PUBLIC_URL + '/garbageColour.png'
                    : process.env.PUBLIC_URL + '/garbageColour.png'
                }
                alt="garbage"
                width="100"
              />
            </Box>
          </Flex>
        </Container>

        <Container
          className="weatherHover"
          opacity="1"
          minWidth="200px"
          minHeight="250px"
          boxShadow={'dark-lg'}
          p={5}
          bg={colorMode === 'light' ? 'white' : 'black'}
          border="lightblue solid"
          borderRadius="15"
          onClick={weatherClick}
          cursor="pointer"
        >
          <Heading textAlign={'center'} pb={5}>
            Weather
          </Heading>
          <Flex justifyContent={'center'}>
            <Box>
              <img
                src={
                  colorMode === 'light'
                    ? process.env.PUBLIC_URL + '/weatherColour.png'
                    : process.env.PUBLIC_URL + '/weatherColour.png'
                }
                alt="weather"
                width="100"
              />
            </Box>
          </Flex>
        </Container>

        <Container
          className="contactHover"
          opacity="1"
          minWidth="200px"
          minHeight="250px"
          boxShadow={'dark-lg'}
          p={5}
          bg={colorMode === 'light' ? 'white' : 'black'}
          border="lightblue solid"
          borderRadius="15"
          onClick={contactClick}
          cursor="pointer"
        >
          <Heading textAlign={'center'} pb={5}>
            Contacts
          </Heading>
          <Flex justifyContent={'center'}>
            <Box>
              <img
                src={
                  colorMode === 'light'
                    ? process.env.PUBLIC_URL + '/contactColour.png'
                    : process.env.PUBLIC_URL + '/contactColour.png'
                }
                alt="weather"
                width="100"
              />
            </Box>
          </Flex>
        </Container>
      </HStack>
    </>
  );
}

export default Home;
