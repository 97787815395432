import { useColorMode } from '@chakra-ui/color-mode';
import { Container, Heading, VStack } from '@chakra-ui/layout';
import React from 'react';
import TodoList from './TodoList';

function Chores() {
  const { colorMode } = useColorMode();

  return (
    <VStack spacing={5}>
      <Heading>Cotty Chores</Heading>
      <Container
        minWidth="400px"
        minHeight="250px"
        boxShadow={'dark-lg'}
        mr={5}
        p={5}
        bg={colorMode === 'light' ? 'white' : 'black'}
        border="lightblue solid"
        borderRadius="15"
      >
        <TodoList />
      </Container>
    </VStack>
  );
}

export default Chores;
