import React, { useState, useEffect } from 'react';
import {
  Box,
  Divider,
  Heading,
  Image,
  Text,
  useColorMode,
  VStack,
} from '@chakra-ui/react';

function TodaysDate() {
  const [calendarWeekday, setCalendarWeekday] = useState('');
  const [calendarDay, setCalendarDay] = useState('');
  const [calendarMonth, setCalendarMonth] = useState('');
  const [calendarYear, setCalendarYear] = useState('');

  const { colorMode } = useColorMode();

  useEffect(() => {
    getFullDate();
  });

  const weekdayNames = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  };

  const monthNames = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  };

  function getFullDate() {
    let newDate = new Date();
    let date = newDate.getDate();
    let year = newDate.getFullYear();
    let dayofweek = newDate.getDay();
    let month = newDate.getMonth();

    setCalendarWeekday(weekdayNames[dayofweek]);
    setCalendarDay(date);
    setCalendarMonth(monthNames[month]);
    setCalendarYear(year);
  }

  return (
    <VStack>
      <VStack spacing={5}>
        <Box>
          <Image
            filter={colorMode === 'light' ? 'none' : 'invert(1)'}
            boxSize="150px"
            border="black solid"
            borderRadius="15"
            color="inverse"
            src="https://dcassetcdn.com/design_img/2306814/617327/617327_12260572_2306814_071bd951_thumbnail.png"
          />
        </Box>
        <Heading>Consolation Island App</Heading>
        <Divider
          margin={10}
          width={250}
          opacity={0.8}
          borderBottomWidth={'3px'}
        />
      </VStack>
      <Box mr={5}>
        <Heading>{calendarWeekday}</Heading>
        <Text>
          {calendarMonth} {calendarDay}, {calendarYear}{' '}
        </Text>
      </Box>
    </VStack>
  );
}

export default TodaysDate;
